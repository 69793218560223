// Переменные для цветов
$color-background-menubar: #fff;
$color-border-menubar: #fff;
$color-text-default: #a4a4a4;
$color-text-active: #2b2b2b;
$color-icon-default: #81a1c1;
$color-background-hover: #fff;
$color-background-focus: #fff;
$color-background-highlight: #fff;
$color-background-active: #fff; // Цвет фона для активного элемента
$color-box-shadow-hover: #fff; // Цвет тени при hover
$color-active-border: #ff7171;

// Font style
$font-weight-default: 500;
$font-size-default: 0.9375rem;
$font-line-height-default: 1.5rem;

// Переменные для размеров
$padding-menubar: 0.5rem; // 8px
$padding-menuitem: 0.75rem 1.875rem; // 12px 30px
$margin-icon-right: 0.5rem; // 8px
$margin-submenu-icon-left: 0.5rem; // 8px

// Переменные для границ и радиусов
$border-width-menubar: 0;
$border-radius-menubar: 0;
$border-radius-menuitem: 0;

// SCSS для Menubar
.header {
  &__menubar {
    //
    width: 100%;

    //
    background-color: $color-background-menubar; // Цвет фона Menubar
    border: $border-width-menubar solid $color-border-menubar; // Граница
    border-radius: $border-radius-menubar; // Радиус углов
    padding: $padding-menubar; // Внутренние отступы
    //
    font-weight: $font-weight-default;
    font-size: $font-size-default;
    line-height: $font-line-height-default;

    @media screen and (min-width: 960px) {
      height: 100%;
      padding: 0 0.5rem;

      .p-menubar-root-list {
        margin-left: 5rem;
        height: 100%;

        .p-menuitem {
          margin-top: 1px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 2px inset transparent;

          &.p-menuitem-active {
            border-bottom: 2px inset $color-active-border;
          }
        }
      }
    }

    //
    .p-menubar-root-list {
      outline: none; // Убираем обводку
    }

    // Стили для элементов меню
    .p-menuitem > .p-menuitem-content {
      color: $color-text-default; // Основной цвет текста для контента меню
      transition: none; // Убираем анимации
      border-radius: $border-radius-menuitem; // Радиус углов для элементов меню

      // Ссылки внутри пунктов меню
      .p-menuitem-link {
        padding: $padding-menuitem; // Отступы внутри ссылки
        user-select: none; // Запрещаем выделение текста
        color: $color-text-default; // Цвет текста ссылки

        // Текст внутри ссылки
        .p-menuitem-text {
          color: $color-text-default; // Цвет текста по умолчанию
        }

        // Иконки в ссылках
        .p-menuitem-icon {
          color: $color-icon-default; // Цвет иконок
          margin-right: $margin-icon-right; // Отступ справа для иконок
        }

        .p-submenu-icon {
          color: $color-icon-default; // Цвет подменю иконок
          margin-left: $margin-submenu-icon-left; // Отступ слева для иконок подменю
        }

        // Состояние при наведении (hover) для активных и неактивных ссылок
        &:not(.p-disabled):hover {
          box-shadow: inset 0 0 0 0.1rem $color-box-shadow-hover; // Внутренняя тень при hover
        }
      }
    }

    // Состояние при наведении (hover)
    .p-menuitem:not(.p-highlight, .p-disabled, .p-menuitem-active) > .p-menuitem-content:hover {
      background-color: $color-background-hover; // Цвет фона при наведении
      color: $color-text-default; // Цвет текста на уровне .p-menuitem-content

      .p-menuitem-link {
        color: $color-text-default; // Цвет текста внутри ссылки при наведении

        // Применение правила для текста ссылки
        .p-menuitem-text {
          color: $color-text-default; // Цвет текста внутри .p-menuitem-text при наведении
        }

        // Иконки при наведении
        .p-menuitem-icon,
        .p-submenu-icon {
          color: $color-icon-default; // Цвет иконок при наведении
        }
      }
    }

    // Состояние фокуса (focus)
    .p-menuitem:not(.p-highlight, .p-disabled, .p-menuitem-active).p-focus > .p-menuitem-content {
      background-color: $color-background-focus; // Цвет фона при фокусе
      color: $color-text-default; // Цвет текста на уровне .p-menuitem-content при фокусе

      .p-menuitem-link {
        color: $color-text-default; // Цвет текста внутри ссылки при фокусе

        // Применение правила для текста ссылки при фокусе
        .p-menuitem-text {
          color: $color-text-default; // Цвет текста внутри .p-menuitem-text при фокусе
        }

        // Иконки при фокусе
        .p-menuitem-icon,
        .p-submenu-icon {
          color: $color-text-default; // Цвет иконок при фокусе
        }
      }
    }

    // Состояние активного элемента (highlight и p-menuitem-active)
    .p-menuitem.p-highlight,
    .p-menuitem.p-menuitem-active > .p-menuitem-content {
      background-color: $color-background-active; // Цвет фона для активного элемента
      color: $color-text-active; // Цвет текста для активного элемента

      .p-menuitem-link {
        color: $color-text-active; // Цвет текста для активных ссылок

        // Применение правила для текста активного элемента
        .p-menuitem-text {
          color: $color-text-active; // Цвет текста внутри .p-menuitem-text для активного элемента
        }

        // Иконки для активного элемента
        .p-menuitem-icon,
        .p-submenu-icon {
          color: $color-icon-default; // Цвет иконок для активного элемента
        }
      }
    }

    //

    .p-menubar-start,
    .p-menubar-end,
    .p-menubar {
      display: flex;
      align-items: center;
    }

    .p-menubar {
      justify-content: space-between;
    }

    @media (max-width: 768px) {
      /* Логотип центрируется */
      .p-menubar-start {
        flex-grow: 1;
        display: flex;
        justify-content: center;
      }

      /* Перемещаем бургер-меню влево */
      .p-menubar-button {
        order: -1; /* Перемещаем бургер-меню в начало */
        margin-right: auto; /* Отправляем его влево */
      }
    }

    @media screen and (max-width: 960px) {
      .p-menubar-button {
        color: $color-text-active;

        &:focus {
          box-shadow: 0 0 0 0.2rem $color-text-default;
        }
      }
    }
  }
}
