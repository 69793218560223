$bg-light-grey: #f4f7fb; // vars
$text-black: #2b2b2b;

.jetsaver {
  .form {
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    width: 35rem;
    height: 41.35rem;
    border-radius: 0.75rem;
    padding: 3.75rem;
    padding-top: 4rem;
    background: #fff;
    box-shadow: 0 0.75rem 2rem rgb(38 55 116 / 10%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: $text-black;

    .form-col {
    }

    &__header {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;

      &--row-directed {
        flex-direction: row;
        gap: 1rem;

        .main-button,
        .secondary-button {
          min-width: 8.75rem;
        }
      }

      &--align-right {
        justify-content: flex-end;
      }
    }

    // Block form-description
    &-description {
      margin-top: 0.5rem;
    }

    .p-error {
      font-style: normal;
      font-weight: 400;
      font-size: 0.6875rem;
      line-height: 1.125rem;
    }

    .float-label {
      margin-top: 0.9rem;
      margin-left: 0.25rem;
    }

    .field-checkbox {
      margin-bottom: 0.5rem;
    }

    // Element Close button
    &__close-button {
      background: none;
      border: none;
      align-self: flex-end;

      &:focus {
        box-shadow: 0 0 0 0.2rem #f5f5f5; // todo move to vars
        outline: 0 none;
        outline-offset: 0;
      }
    }

    .field-image-upload {
      border-radius: 0.75rem;
      background: $bg-light-grey;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
    }

    .header3 {
      margin-top: 2.1rem;
      margin-bottom: 0;
    }

    .header4 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .form-header-icon {
      width: 10.25rem;
      height: 10.25rem;
      min-height: 10.25rem;
      background: $bg-light-grey;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header2--form {
      margin-top: 1.1rem;
    }

    // Form modifiers

    &--wide {
      width: 70rem;
    }

    &--w-full {
      width: 100%;
    }

    &--in-dialog {
      .header2--form {
        margin-top: 0;
      }
    }

    &--tab-adapted {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-top: 1.25rem;
    }

    &--profile-personal-info {
      //none
    }

    &--profile-account {
      //none
    }

    &--login-form {
      margin-top: 4.7rem; // todo move to page geometry form
    }

    &--new-password {
      padding-top: 5rem;
      margin-top: 4.7rem; // todo move to page geometry form
    }

    &--reset-password {
      padding-top: 5rem;
      margin-top: 4.7rem; // todo move to page geometry form
    }

    &--register-general-info {
      padding-top: 1.7rem;
      margin-top: 4.7rem; // todo move to page geometry form
    }

    &--register-personal-id,
    &--register-personal-id-upload {
      padding-top: 1.7rem;
      margin-top: 4.7rem; // todo move to page geometry form
    }

    &--search-ticket {
      padding: 1.5rem 1rem;
      display: flex;
      height: auto;
      justify-content: space-between;
      align-items: flex-start;

      //lg
      @media screen and (min-width: 992px) {
        flex-direction: row;

        .field {
          margin-bottom: 0;
        }

        .form-col {
          width: 21.5%;

          &--search-btn {
            // width: auto;
            width: 14%;
          }
        }
      }
    }

    //sm
    @media screen and (min-width: 576px) {
    }

    // md
    @media screen and (min-width: 768px) {
    }

    // lg
    @media screen and (min-width: 992px) {
    }

    // xl
    @media screen and (min-width: 1200px) {
    }
  }

  .suggestions-list {
    list-style: none;
  }
}
