.flight {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff; // todo use variable
  border-radius: 0.5rem;

  // S4 - TODO see variants
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 1.125rem;
  padding-bottom: 3.5rem;

  //

  &-photos {
    display: flex;
    min-height: 100px;
    width: 100%;
    order: 1;
    padding: 0.5rem;
  }

  &-details {
    padding: 0.5rem;
    display: flex;

    // background-color: aqua;
    min-height: 100px;
    width: 100%;
    order: 0;
    flex-direction: column;

    &__description {
      background: #f4f7fb;
      padding: 0.25rem;
      border-radius: 0.5rem;
    }
  }

  //sm
  @media screen and (min-width: 576px) {
  }

  // md
  @media screen and (min-width: 768px) {
    flex-direction: row;

    &-photos {
      width: 50%;
      order: 0;
    }

    &-details {
      width: 50%;
      order: 1;
    }
  }

  // lg
  @media screen and (min-width: 992px) {
  }

  // xl
  @media screen and (min-width: 1200px) {
  }
}
