@use "../../app/theme";

$card-main-border-radius: 0.75rem;
$color-white: #fff;
$main-black: #2b2b2b;
$color-gray02: #a4a4a4;
$primary-color: #ff7171;
$chip-offset: 1.125rem;
$chip-tooltip-padding: 1rem 1.5rem;
$chip-tooltip-border-radius: 0.5rem;

.jetsaver {
  .user-ticket-collection {
    width: 100%;
  }

  .user-ticket-card {
    margin-top: $chip-offset;
    padding-top: $chip-offset;

    .header4 {
      color: $color-white;
      margin: 0;
    }

    &--pending,
    &--paid,
    &--completed,
    &--cancelled {
      margin-top: $chip-offset;
      padding-top: 0;
    }

    &.p-card {
      border-radius: $card-main-border-radius;

      .p-card-body {
        padding-top: 0;

        .p-card-content {
          padding-top: 0;
        }
      }
    }

    &__status {
      display: flex;
      justify-content: flex-end;
      gap: 0.25rem;
    }

    .aircraft-info {
      border-radius: $card-main-border-radius;
    }

    .aircraft-info-image {
      border-radius: $card-main-border-radius;
    }

    .aircraft-info-content {
      border-radius: $card-main-border-radius;
    }

    &-route {
      padding-bottom: 1rem;

      .direct-icon {
        width: 1.5rem;
        height: 1.5rem;

        > svg {
          width: 100%;
          height: 100%;
        }
      }

      .airport-code {
        color: $color-gray02;
      }

      &__from,
      &__to {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      &__to {
        margin-top: 0.75rem;
      }
    }

    &-footer {
      display: flex;
      justify-content: center;
      padding: 0.5rem;
      padding-top: 1rem;

      .fill-info-button {
        width: 100%;
      }
    }
  }

  /// Chips

  .p-chip {
    &.user-ticket-chip {
      @extend .content-b1;

      margin-top: -$chip-offset;
      color: #fff;
      background-color: $main-black;

      &--date-countdown {
        background-color: $primary-color;
      }
    }
  }
}

// Chips tooltips - tooltips layer is not in .jetsaver class namespace

.user-ticket-chip {
  &__tooltip {
    .p-tooltip-text {
      @extend .content-s2;

      color: $color-white;
      padding: $chip-tooltip-padding;
      background: $main-black;
      border-radius: $chip-tooltip-border-radius;

      // color: red;
    }
  }
}
