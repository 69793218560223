.jetsaver {
  .buy-flight {
    .price-calculator {
      display: flex;
      justify-content: flex-end;
      gap: 2rem;

      &__seats {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 8rem;
      }

      &__price {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 8rem;
      }

      @media screen and (min-width: 576px) {
        gap: 3rem;

        &__seats {
          min-width: 10rem;
        }

        &__price {
          min-width: 10rem;
        }
      }
    }

    .main-button.p-button,
    .secondary-button.p-button {
      padding: 0.25rem;
      font-size: 0.65rem;
      line-height: 1rem;
      font-weight: 400;
    }

    @media screen and (min-width: 576px) {
      .main-button.p-button,
      .secondary-button.p-button {
        padding: 0.75rem 1.75rem;
        font-size: 0.9375rem;
        line-height: 1.5rem;
        font-weight: 700;
      }
    }

    .header5 {
      font-size: 1rem;
      line-height: 1.5rem;
      vertical-align: middle;
      display: flex;
      margin: 0;

      .count-button {
        font-size: 2rem;
        cursor: pointer;
        user-select: none;
      }

      .count-value {
        padding: 0 0.5rem;
      }

      @media screen and (min-width: 576px) {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }
}
